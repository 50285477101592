import React from "react";
import { Link } from "react-router-dom";
import ToTop from "../includes/ToTop";
import $ from "jquery";
import { useFormik } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import AdminListService from "../../../services/admin-list.service";
import Pagination from "react-js-pagination";
import { useRef } from "react";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { updateCertiVlidation } from "../../../schema";
import moment from "moment";
import { Helmet } from "react-helmet";
import secureLocalStorage from "react-secure-storage";
import Select from "react-select";

export default function PavlinaIndex() {
  const state = useSelector((state) => state.stateVals);

  const { pavlina_id } = state;

  const [loading, setLoading] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [showCertiRecord, setShowCertiRecord] = useState(false);
  const [CertifecateRecordData, setCertifecateRecordData] = useState([]);
  const [JobDetailsData, setJobDetailsData] = useState([]);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("20");
  const [StatesList, setStatesList] = useState([]);
  const [adminCards, setAdminCards] = useState([]);
  const [EditId, setEditId] = useState("");
  const [exportLink, setExportLink] = useState("");
  const [emailStatus, setEmailStatus] = useState([]);

  const getCardsAdmin = async () => {
    try {
      const responce = await AdminListService.getAdminCards();

      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        return results.push({
          value: value.CardName.toString(),
          label: value.CardName.toString(),
        });
      });
      setAdminCards([...results]);
    } catch (err) {
      console.log(err);
      setAdminCards([]);
    }
  };
  const getStatesNames = async () => {
    try {
      const responce = await AdminListService.getStates();

      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        return results.push({
          value: value.code,
          label: value.state_name,
        });
      });
      setStatesList([...results]);
    } catch (err) {
      console.log(err);
      setStatesList([]);
    }
  };
  useEffect(() => {
    if (pavlina_id) {
      getStatesNames();

      getCardsAdmin();

      if (secureLocalStorage.getItem("certiEdit")) {
        const recrdId = secureLocalStorage.getItem("certiEdit");
        setEditId(recrdId);
        editCerti(recrdId);
        secureLocalStorage.removeItem("certiEdit");
      }
    }

    // eslint-disable-next-line
  }, []);

  const sendMailUserById = async (id) => {
    setLoading(true);
    try {
      const responce = await AdminListService.sendMailById(id);

      if (responce.status === 200) {
        setLoading(false);
      }

      setEmailStatus((prevEmailStatus) => ({
        ...prevEmailStatus,
        [id]: "Email sent successfully",
      }));
    } catch (err) {
      console.log(err);
      setLoading(false);
      setEmailStatus((prevEmailStatus) => ({
        ...prevEmailStatus,
        [id]: "Error in sending email",
      }));
    }
  };

  const selectKey = useRef(null);
  const statusKey = useRef(null);

  const changeRecieveMethod = (value) => {
    //alert(value);

    if (value === "Active") {
      $(".change-color-this").css("background", "green");

      $("#ceckstatus").css("background", "green");
    }
    if (value === "Voucher Mailed") {
      $(".change-color-this").css("background", "purple");
      $("#ceckstatus").css("background", "purple");
    }
    if (value === "Voucher Received") {
      $(".change-color-this").css("background", "SandyBrown");
      $("#ceckstatus").css("background", "SandyBrown");
    }
    if (value === "Card Ordered") {
      $(".change-color-this").css("background", "pink");
      $("#ceckstatus").css("background", "pink");
    }
    if (value === "Card Mailed") {
      $(".change-color-this").css("background", "SkyBlue");
      $("#ceckstatus").css("background", "SkyBlue");
    }
    if (value === "Expired") {
      $(".change-color-this").css("background", "Salmon");
      $("#ceckstatus").css("background", "Salmon");
    }
    if (value === "Void") {
      $(".change-color-this").css("background", "red");
      $("#ceckstatus").css("background", "red");
    }
  };

  const getCertiRecord = async (values, pageNo) => {
    setLoading(true);
    try {
      const responce = await AdminListService.searchCertRecord(values, pageNo);

      let res;

      if (responce.status === 200) {
        setExportLink(
          process.env.REACT_APP_API_Link +
            "exportCertificates/page=" +
            pageNo +
            "@@certificate=@@voucher=" +
            values.vouchNo +
            "@@begin=" +
            values.redemSt +
            "@@end=" +
            values.redemEd +
            "@@job=" +
            values.jobNo +
            "@@cardType=" +
            values.cardType +
            "@@phone=" +
            values.phone +
            "@@certStatus=" +
            values.certiStatus +
            "@@customer=" +
            values.custName +
            "@@firstName=" +
            values.fName +
            "@@lastName=" +
            values.lName +
            "@@address=" +
            values.address +
            "@@city=" +
            values.city +
            "@@state=" +
            values.state +
            "@@zip=" +
            values.zipCode +
            "@@denomination=" +
            values.denom +
            "@@receiveMethod=" +
            values.recMeth +
            "@@mailDate=" +
            values.statusDate +
            "@@cardOrdered=@@fileNote=" +
            "@@code=" +
            values.prdType +
            "@@certNumber1=" +
            values.certiNo +
            "@@uniqueId=@@emailAddress=" +
            values.email +
            "@@paNumber=" +
            values.paNo +
            "@@invoiceNo=" +
            values.invcNo +
            "@@clientCost=" +
            values.clCost +
            "@@redeemDate=" +
            values.RedeemedOnDate +
            "@@random_certificate=" +
            values.randNo
        );

        res = responce.data.data.data;

        const results = [];

        res.map((value) => {
          return results.push({
            groupNo: value.groupNo,
            id: value.id,

            FirstName: value.FIRST_NAME,
            LastName: value.LAST_NAME,
            Address: value.ADDRESS,
            City: value.CITY,
            State: value.STATE,
            ZipCode: value.ZIP,
            PhoneNumber: value.PHONE_NUMBER,
            Email: value.EmailAddress,
            Denomination: value.DENOMINATION,
            certNo: value.CERT_NUMBER,
            redBegin: value.REDEMPTION_BEINS,
            redEnd: value.REDEMPTION_ENDS,
            jobNo: value.JOB_NUMBER,
            certStatus: value.CERT_STATUS,
            randomCert: value.random_certificate,
            company: value.CUSTOMER,
            status: value.STATUS,
          });
        });

        setCertifecateRecordData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);

        if (
          values.certiNo !== "" &&
          responce?.data?.data?.total_records === 1
        ) {
          await certiSearch.setValues({
            fName: responce?.data?.data?.data[0]?.FIRST_NAME,
            lName: responce?.data?.data?.data[0]?.LAST_NAME,
            address: responce?.data?.data?.data[0]?.ADDRESS,
            city: responce?.data?.data?.data[0]?.CITY,
            state: responce?.data?.data?.data[0]?.STATE,
            zipCode: responce?.data?.data?.data[0]?.ZIP,
            email: responce?.data?.data?.data[0]?.EmailAddress,
            phone: responce?.data?.data?.data[0]?.PHONE_NUMBER,
            certiNo: responce?.data?.data?.data[0]?.CERT_NUMBER_1,
            custName: responce?.data?.data?.data[0]?.CUSTOMER,
            vouchNo: responce?.data?.data?.data[0]?.voucher,
            denom: responce?.data?.data?.data[0]?.DENOMINATION,
            paNo: responce?.data?.data?.data[0]?.pa_number,
            randNo: responce?.data?.data?.data[0]?.random_certificate,
            clCost: responce?.data?.data?.data[0]?.ClientCost,
            redemSt: responce?.data?.data?.data[0]?.REDEMPTION_BEINS,
            redemEd: responce?.data?.data?.data[0]?.REDEMPTION_ENDS,

            invcNo: responce?.data?.data?.data[0]?.InvoiceNo,
            prdType: responce?.data?.data?.data[0]?.product_type,
            RedeemedOnDate: moment(
              responce?.data?.data?.data[0]?.Redeemed_On_Date
            ).format("YYYY-MM-DD"),
            jobNo: responce?.data?.data?.data[0]?.JOB_NUMBER,
            cardType: responce?.data?.data?.data[0]?.CardType,
            certiStatus: responce?.data?.data?.data[0]?.CERT_STATUS,
            statusDate: responce?.data?.data?.data[0]?.status_date,
            businNo: responce?.data?.data?.data[0]?.na_visio,
            recMeth: responce?.data?.data?.data[0]?.RECEIVED_METHOD,
          });

          changeRecieveMethod(responce?.data?.data?.data[0]?.CERT_STATUS);

          setEditId(responce?.data?.data?.data[0]?.id);
          // secureLocalStorage.setItem(
          //   "certiEdit",
          //   responce?.data?.data?.data[0]?.id
          // );
          setIsEditing(true);
        }

        setLoading(false);
        setShowCertiRecord(true);
      }
    } catch (err) {
      console.log(err);
      setCertifecateRecordData([]);
      setTotalResults(0);
      setTotalPages(0);
      setExportLink("");
      setLoading(false);
      setShowCertiRecord(true);
    }
  };

  const checkAgainstJob = async (job) => {
    setLoading(true);
    try {
      const responce = await AdminListService.checkJobData(job);

      let res;

      const results = [];
      if (responce.status === 200) {
        res = responce.data.data;
        results.push({
          job_no: res.job_no,
          totalCert: res.totalCert,
          totalUsedCert: res.totalUsedCert,
          start_date: res.start_date,
          end_date: res.end_date,
          total_percentage_used_certificates:
            res.total_percentage_used_certificates,
        });

        setJobDetailsData([...results]);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);

      setJobDetailsData([]);

      setLoading(false);
    }
  };
  const [duplicateCerti, setDuplicateCerti] = useState("");

  const updateCertInfo = async (values, action) => {
    setLoading(true);
    setShowCertiRecord(false);
    try {
      const responce = await AdminListService.updateCerti(
        pavlina_id,
        values,
        EditId
      );

      if (responce.status === 200) {
        setLoading(false);
        action.resetForm();
        setIsEditing(false);
        $(".change-color-this").css("background", "");
        $("#ceckstatus").css("background", "");
        setDuplicateCerti("");

        Swal.fire({
          icon: "success",
          title: "Updated Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        await setAdminCards([]);
        await setStatesList([]);
        getCardsAdmin();

        getStatesNames();
        // secureLocalStorage.removeItem("certiEdit");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);

      if (err?.response?.status === 409) {
        setDuplicateCerti("Certificate already exist");
      } else {
        setDuplicateCerti("");
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Some thing went wrong!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      }
    }
  };

  const certiSearch = useFormik({
    initialValues: {
      certiNo: "",
      custName: "",
      businNo: "",
      vouchNo: "",
      denom: "",
      paNo: "",
      randNo: "",
      clCost: "",
      redemSt: "",
      redemEd: "",
      complnId: "",
      invcNo: "",
      prdType: "",
      RedeemedOnDate: "",
      jobNo: "",
      fName: "",
      lName: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      phone: "",
      email: "",
      cardType: "",
      certiStatus: "",
      statusDate: "",
      recMeth: "",
    },
    validationSchema: isEditing ? updateCertiVlidation : null,
    onSubmit: (values, action) => {
      if (isEditing) {
        updateCertInfo(values, action);
      } else {
        getCertiRecord(values, "1");
        if (values.jobNo !== "") {
          secureLocalStorage.setItem("jobSearch", values.jobNo);
          checkAgainstJob(values.jobNo);
        } else {
          setJobDetailsData([]);
          secureLocalStorage.removeItem("jobSearch");
        }
        if (values.certiNo !== "") {
          secureLocalStorage.setItem("cert", values.certiNo);
        } else {
          secureLocalStorage.removeItem("cert");
        }
        if (values.custName !== "") {
          secureLocalStorage.setItem("customer", values.custName);
        } else {
          secureLocalStorage.removeItem("customer");
        }
        if (values.vouchNo !== "") {
          secureLocalStorage.setItem("voucher", values.vouchNo);
        } else {
          secureLocalStorage.removeItem("voucher");
        }
        if (values.denom !== "") {
          secureLocalStorage.setItem("denomination", values.denom);
        } else {
          secureLocalStorage.removeItem("denomination");
        }
        if (values.paNo !== "") {
          secureLocalStorage.setItem("paNumber", values.paNo);
        } else {
          secureLocalStorage.removeItem("paNumber");
        }
        if (values.randNo !== "") {
          secureLocalStorage.setItem("random_certificate", values.randNo);
        } else {
          secureLocalStorage.removeItem("random_certificate");
        }
        if (values.clCost !== "") {
          secureLocalStorage.setItem("clientCost", values.clCost);
        } else {
          secureLocalStorage.removeItem("clientCost");
        }
        if (values.redemSt !== "") {
          secureLocalStorage.setItem("begin", values.redemSt);
        } else {
          secureLocalStorage.removeItem("begin");
        }
        if (values.redemEd !== "") {
          secureLocalStorage.setItem("end", values.redemEd);
        } else {
          secureLocalStorage.removeItem("end");
        }
        if (values.invcNo !== "") {
          secureLocalStorage.setItem("invoiceNo", values.invcNo);
        } else {
          secureLocalStorage.removeItem("invoiceNo");
        }
        if (values.RedeemedOnDate !== "") {
          secureLocalStorage.setItem("redeemDate", values.RedeemedOnDate);
        } else {
          secureLocalStorage.removeItem("redeemDate");
        }
        if (values.fName !== "") {
          secureLocalStorage.setItem("firstName", values.fName);
        } else {
          secureLocalStorage.removeItem("firstName");
        }
        if (values.lName !== "") {
          secureLocalStorage.setItem("lastName", values.lName);
        } else {
          secureLocalStorage.removeItem("lastName");
        }
        if (values.address !== "") {
          secureLocalStorage.setItem("address", values.address);
        } else {
          secureLocalStorage.removeItem("address");
        }

        if (values.city !== "") {
          secureLocalStorage.setItem("city", values.city);
        } else {
          secureLocalStorage.removeItem("city");
        }
        if (values.state !== "") {
          secureLocalStorage.setItem("stateName", values.state);
        } else {
          secureLocalStorage.removeItem("stateName");
        }

        if (values.zipCode !== "") {
          secureLocalStorage.setItem("zip", values.zipCode);
        } else {
          secureLocalStorage.removeItem("zip");
        }

        if (values.phone !== "") {
          secureLocalStorage.setItem("phone", values.phone);
        } else {
          secureLocalStorage.removeItem("phone");
        }

        if (values.email !== "") {
          secureLocalStorage.setItem("emailAddress", values.email);
        } else {
          secureLocalStorage.removeItem("emailAddress");
        }

        if (values.cardType !== "") {
          secureLocalStorage.setItem("cardType", values.cardType);
        } else {
          secureLocalStorage.removeItem("cardType");
        }

        if (values.certiStatus !== "") {
          secureLocalStorage.setItem("certStatus", values.certiStatus);
        } else {
          secureLocalStorage.removeItem("certStatus");
        }

        if (values.recMeth !== "") {
          secureLocalStorage.setItem("receiveMethod", values.recMeth);
        } else {
          secureLocalStorage.removeItem("receiveMethod");
        }

        if (values.statusDate !== "") {
          secureLocalStorage.setItem("statusDate", values.statusDate);
        } else {
          secureLocalStorage.removeItem("statusDate");
        }
        if (values.prdType !== "") {
          secureLocalStorage.setItem("prdType", values.prdType);
        } else {
          secureLocalStorage.removeItem("prdType");
        }
      }
    },
  });

  const customSearchRecord = async (e) => {
    setLoading(true);
    const key = await selectKey.current.value;
    const keyVal = e.target.value;
    try {
      const responce = await AdminListService.searchCustomRecord(key, keyVal);

      let res;

      if (responce.status === 200) {
        res = responce.data.data.data;

        const results = [];

        res.map((value) => {
          return results.push({
            groupNo: value.groupNo,
            id: value.id,

            FirstName: value.FIRST_NAME,
            LastName: value.LAST_NAME,
            Address: value.ADDRESS,
            City: value.CITY,
            State: value.STATE,
            ZipCode: value.ZIP,
            PhoneNumber: value.PHONE_NUMBER,
            Email: value.EmailAddress,
            Denomination: value.DENOMINATION,
            certNo: value.CERT_NUMBER,
            redBegin: value.REDEMPTION_BEINS,
            redEnd: value.REDEMPTION_ENDS,
            jobNo: value.JOB_NUMBER,
            certStatus: value.CERT_STATUS,
            randomCert: value.random_certificate,
            company: value.CUSTOMER,
            status: value.STATUS,
          });
        });

        setCertifecateRecordData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setCertifecateRecordData([]);
      setTotalResults(0);
      setTotalPages(0);
      setLoading(false);
    }
  };

  const changeAllRecords = async () => {
    setLoading(true);
    const status = await statusKey.current.value;
    const recordIds = await CertifecateRecordData.map((record) => record.id);
    if (recordIds.length > 0) {
      if (status === "") {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Please select status!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      } else {
        const recordIdsString = recordIds.join(",");
        try {
          const responce = await AdminListService.allRecordChange(
            pavlina_id,
            status,
            recordIdsString
          );

          if (responce.status === 200) {
            setLoading(false);
            getCertiRecord(certiSearch.values, currentPage);
          }
        } catch (err) {
          console.log(err);

          setLoading(false);
        }
      }
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        text: "No record avaliable!",
        confirmButtonColor: "#e72e2e",
        confirmButtonText: "Try Again",
      });
    }
  };

  const deActivate = async (id) => {
    setLoading(true);

    try {
      const responce = await AdminListService.deActivateVoucher(id);

      if (responce.status === 200) {
        setLoading(false);
        getCertiRecord(certiSearch.values, currentPage);
      }
    } catch (err) {
      console.log(err);

      setLoading(false);
    }
  };

  const editCerti = async (id) => {
    setLoading(true);

    try {
      const response = await AdminListService.getForEdit(id);

      if (response.status === 200) {
        setLoading(false);

        await certiSearch.setValues({
          fName: response.data.data.FIRST_NAME,
          lName: response.data.data.LAST_NAME,
          address: response.data.data.ADDRESS,
          city: response.data.data.CITY,
          state: response.data.data.STATE,
          zipCode: response.data.data.ZIP,
          email: response.data.data.EmailAddress,
          phone: response.data.data.PHONE_NUMBER,
          certiNo: response.data.data.CERT_NUMBER_1,
          custName: response.data.data.CUSTOMER,
          vouchNo: response.data.data.voucher,
          denom: response.data.data.DENOMINATION,
          paNo: response.data.data.pa_number,
          randNo: response.data.data.random_certificate,
          clCost: response.data.data.ClientCost,
          redemSt: response.data.data.REDEMPTION_BEINS,
          redemEd: response.data.data.REDEMPTION_ENDS,

          invcNo: response.data.data.InvoiceNo,
          prdType: response.data.data.product_type,
          businNo: response.data.data.na_visio,
          RedeemedOnDate: moment(response.data.data.Redeemed_On_Date).format(
            "YYYY-MM-DD"
          ),
          jobNo: response.data.data.JOB_NUMBER,
          cardType: response.data.data.CardType,
          certiStatus: response.data.data.CERT_STATUS,
          statusDate: response.data.data.status_date,
          recMeth: response.data.data.RECEIVED_METHOD,
        });

        changeRecieveMethod(response.data.data.CERT_STATUS);
        setIsEditing(true);
        setShowCertiRecord(false);

        // secureLocalStorage.setItem("certiEdit", id);
      }
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        confirmButtonColor: "#e72e2e",
        text: "Something went wrong!",
      });
      setIsEditing(false);
      setLoading(false);
    }
  };

  const CertificateSearchRecords = ({ data, allData }) => {
    return (
      <>
        {allData.length ? (
          <>
            {data.length ? (
              <div className="table-responsive mt-3">
                <table className="table table-striped table-bordered W-100  align-middle">
                  <thead>
                    <tr>
                      <th>Job Number </th>
                      <th>Total Certificate </th>
                      <th>Total Used Certificates </th>
                      <th>Total Percentage used certificates </th>
                      <th>Begin Redemption </th>
                      <th>Expiration </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((el, index) => (
                      <tr key={index}>
                        <td>{el.job_no}</td>
                        <td>{el.totalCert}</td>
                        <td>{el.totalUsedCert}</td>
                        <td>{el.total_percentage_used_certificates}</td>
                        <td>{el.start_date}</td>
                        <td>{el.end_date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : null}

            <div className="table-responsive mt-3">
              <table className="table table-striped table-bordered W-100  align-middle">
                <thead>
                  <tr>
                    <th>User Info</th>
                    <th>Certificate Info</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allData.map((el, index) => (
                    <tr key={index}>
                      <td>
                        <div>
                          First Name: <strong> {el.FirstName} </strong>{" "}
                        </div>
                        <div>
                          Last Name: <strong> {el.LastName} </strong>
                        </div>
                        <div>
                          Phone Number: <strong> {el.PhoneNumber} </strong>
                        </div>
                        <div>
                          Email: <strong> {el.Email} </strong>
                        </div>
                        <div>
                          Address: <strong> {el.Address} </strong>
                        </div>
                        <div>
                          City: <strong> {el.City} </strong>
                        </div>
                        <div>
                          State: <strong> {el.State} </strong>
                        </div>
                        <div>
                          Zip: <strong> {el.ZipCode} </strong>
                        </div>
                        <div>
                          Denomination: <strong> {el.Denomination} </strong>
                        </div>
                        <div>
                          Company: <strong> {el.company} </strong>
                        </div>
                      </td>
                      <td>
                        <div>
                          Cert Number: <strong> {el.certNo} </strong>
                        </div>
                        <div>
                          Random Certificate: <strong> {el.randomCert} </strong>
                        </div>
                        <div>
                          Begin Redemption: <strong> {el.redBegin} </strong>
                        </div>
                        <div>
                          End Redemption: <strong>{el.redEnd} </strong>
                        </div>
                        <div>
                          Job Number: <strong>{el.jobNo} </strong>
                        </div>
                        <div>
                          Cert Status: <strong> {el.certStatus} </strong>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center gap-3 fs-6">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-success"
                            title="Edit"
                            onClick={() => {
                              editCerti(el.id);
                              setEditId(el.id);
                            }}
                          >
                            <i className="bi bi-pen-fill"></i> Edit
                          </button>
                          {el.status === "Filled" ? null : (
                            <button
                              type="button"
                              className={
                                el.status === "-1"
                                  ? "btn btn-sm btn-outline-primary"
                                  : "btn btn-sm btn-outline-danger"
                              }
                              title="De Activate"
                              onClick={() => {
                                deActivate(el.id);
                              }}
                            >
                              {el.status === "-1" ? (
                                <>
                                  <i className="bi bi-check-square-fill"></i>{" "}
                                  Activate
                                </>
                              ) : (
                                <>
                                  <i className="bi bi-archive-fill"></i> De
                                  Activate
                                </>
                              )}
                            </button>
                          )}
                          {el.certStatus === "Active" ||
                          el.certStatus === "Voucher Mailed" ? (
                            !emailStatus[el.id] ? (
                              <button
                                type="button"
                                className="btn btn-sm btn-outline-secondary"
                                title="Edit"
                                onClick={() => {
                                  sendMailUserById(el.id);
                                }}
                              >
                                <i className="bi bi-envelope-fill"></i> Send
                                Email
                              </button>
                            ) : (
                              <strong>{emailStatus[el.id]}</strong>
                            )
                          ) : null}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {totalResults > limit && totalPages > 1 ? (
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={parseInt(limit)}
                  totalItemsCount={totalResults}
                  className="justify-content-center"
                  onChange={(e) => {
                    getCertiRecord(certiSearch.values, e);
                  }}
                  pageRangeDisplayed={8}
                  itemClass="page-item"
                  linkClass="page-link"
                  firstPageText="First Page"
                  lastPageText="Last Page"
                />
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <h4 className="text-danger">No data found.</h4>
        )}
      </>
    );
  };

  return (
    <div className="wrapper">
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} Pavlina Admin</title>
      </Helmet>

      <main className="page-content customer-contnent">
        <div className="card">
          <div className="card-header py-3">
            <h5 className="mb-0 font-22">
              <div className="alert alert-dark border-0 alert-dismissible fade show change-color-this">
                <div className="text-white">
                  When no certificate number is found in step one always start
                  search by selecting other fields
                </div>
              </div>
            </h5>
          </div>

          <div className="card-body">
            <form
              className="row g-3"
              onSubmit={certiSearch.handleSubmit}
              noValidate
            >
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Certificate Number 1</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Certificate Number 1"
                  name="certiNo"
                  value={certiSearch.values.certiNo || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.certiNo && certiSearch.touched.certiNo ? (
                  <span className="text-danger">
                    {certiSearch.errors.certiNo}
                  </span>
                ) : duplicateCerti !== "" && isEditing ? (
                  <span className="text-danger">{duplicateCerti}</span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Customer Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Customer Name"
                  name="custName"
                  value={certiSearch.values.custName || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.custName && certiSearch.touched.custName ? (
                  <span className="text-danger">
                    {certiSearch.errors.custName}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Business/NaVisions Number</label>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Disabled input example"
                  disabled
                  placeholder="Business/NaVisions Number"
                  name="businNo"
                  value={certiSearch.values.businNo || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.businNo && certiSearch.touched.businNo ? (
                  <span className="text-danger">
                    {certiSearch.errors.businNo}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control "
                  aria-label="Disabled input example"
                  placeholder="Job Number"
                  name="jobNo"
                  value={certiSearch.values.jobNo || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.jobNo && certiSearch.touched.jobNo ? (
                  <span className="text-danger">
                    {certiSearch.errors.jobNo}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Voucher Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Voucher Number"
                  name="vouchNo"
                  value={certiSearch.values.vouchNo || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.vouchNo && certiSearch.touched.vouchNo ? (
                  <span className="text-danger">
                    {certiSearch.errors.vouchNo}
                  </span>
                ) : null}
              </div>

              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Denomination </label>
                <input
                  type="text"
                  className="form-control disabled"
                  placeholder="Denomination"
                  name="denom"
                  value={certiSearch.values.denom || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.denom && certiSearch.touched.denom ? (
                  <span className="text-danger">
                    {certiSearch.errors.denom}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">PA Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="PA Number"
                  name="paNo"
                  value={certiSearch.values.paNo || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.paNo && certiSearch.touched.paNo ? (
                  <span className="text-danger">{certiSearch.errors.paNo}</span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Random Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Random Number"
                  name="randNo"
                  value={certiSearch.values.randNo || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.randNo && certiSearch.touched.randNo ? (
                  <span className="text-danger">
                    {certiSearch.errors.randNo}
                  </span>
                ) : null}
              </div>

              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Client Cost</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Client Cost"
                  name="clCost"
                  value={certiSearch.values.clCost || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.clCost && certiSearch.touched.clCost ? (
                  <span className="text-danger">
                    {certiSearch.errors.clCost}
                  </span>
                ) : null}
              </div>

              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Redemption Period Begin</label>
                <input
                  type="date"
                  className="form-control "
                  aria-label="Disabled input example"
                  disabled
                  placeholder="Redemption Period Begin"
                  name="redemSt"
                  value={certiSearch.values.redemSt || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.redemSt && certiSearch.touched.redemSt ? (
                  <span className="text-danger">
                    {certiSearch.errors.redemSt}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Redemption Period End</label>
                <input
                  type="date"
                  className="form-control "
                  aria-label="Disabled input example"
                  disabled
                  placeholder="Redemption Period End"
                  name="redemEd"
                  value={certiSearch.values.redemEd || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.redemEd && certiSearch.touched.redemEd ? (
                  <span className="text-danger">
                    {certiSearch.errors.redemEd}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Compain ID No.</label>
                <input
                  type="Compain ID No."
                  className="form-control "
                  aria-label="Disabled input example"
                  disabled
                  placeholder="Compain ID No."
                  name="complnId"
                  value={certiSearch.values.complnId || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.complnId && certiSearch.touched.complnId ? (
                  <span className="text-danger">
                    {certiSearch.errors.complnId}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Invoice No</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Invoice No"
                  name="invcNo"
                  value={certiSearch.values.invcNo || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.invcNo && certiSearch.touched.invcNo ? (
                  <span className="text-danger">
                    {certiSearch.errors.invcNo}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Product Type</label>
                <input
                  type="number"
                  className="form-control "
                  aria-label="Disabled input example"
                  disabled
                  placeholder="Product Type"
                  name="prdType"
                  value={certiSearch.values.prdType || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.prdType && certiSearch.touched.prdType ? (
                  <span className="text-danger">
                    {certiSearch.errors.prdType}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Redeemed On Date(m/d/y)</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Redeemed On Date(m/d/y)"
                  name="RedeemedOnDate"
                  value={certiSearch.values.RedeemedOnDate || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.RedeemedOnDate &&
                certiSearch.touched.RedeemedOnDate ? (
                  <span className="text-danger">
                    {certiSearch.errors.RedeemedOnDate}
                  </span>
                ) : null}
              </div>

              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  name="fName"
                  value={certiSearch.values.fName || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.fName && certiSearch.touched.fName ? (
                  <span className="text-danger">
                    {certiSearch.errors.fName}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  name="lName"
                  value={certiSearch.values.lName || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.lName && certiSearch.touched.lName ? (
                  <span className="text-danger">
                    {certiSearch.errors.lName}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Address</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address"
                  name="address"
                  value={certiSearch.values.address || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.address && certiSearch.touched.address ? (
                  <span className="text-danger">
                    {certiSearch.errors.address}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">City</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="City"
                  name="city"
                  value={certiSearch.values.city || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.city && certiSearch.touched.city ? (
                  <span className="text-danger">{certiSearch.errors.city}</span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">State</label>
                {/* <select
                  className="form-select dark-border"
                  name="state"
                  value={certiSearch.values.state || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                >
                  <option value="">Select State</option>
                  {StatesList.map((el, index) => {
                    return (
                      <option key={index} value={el.value}>
                        {el.label}
                      </option>
                    );
                  })}
                </select> */}
                {StatesList.length ? (
                  <Select
                    className="form-label dark-border"
                    placeholder={
                      isEditing && certiSearch.values.state !== ""
                        ? certiSearch.values.state
                        : "Select State"
                    }
                    options={StatesList}
                    isSearchable={true}
                    isClearable={true}
                    name="state"
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        certiSearch.setFieldValue(
                          "state",
                          selectedOption.value
                        );
                      } else {
                        certiSearch.setFieldValue("state", "");
                      }
                    }}
                    onBlur={() => certiSearch.setFieldTouched("state", true)}
                  />
                ) : null}

                {certiSearch.errors.state && certiSearch.touched.state ? (
                  <span className="text-danger">
                    {certiSearch.errors.state}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Zip</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Zip"
                  name="zipCode"
                  value={certiSearch.values.zipCode || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.zipCode && certiSearch.touched.zipCode ? (
                  <span className="text-danger">
                    {certiSearch.errors.zipCode}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone Number"
                  name="phone"
                  value={certiSearch.values.phone || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.phone && certiSearch.touched.phone ? (
                  <span className="text-danger">
                    {certiSearch.errors.phone}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Email Address</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  name="email"
                  value={certiSearch.values.email || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.email && certiSearch.touched.email ? (
                  <span className="text-danger">
                    {certiSearch.errors.email}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Card Type</label>

                {adminCards.length ? (
                  <Select
                    className="form-label "
                    placeholder={
                      isEditing && certiSearch.values.cardType !== ""
                        ? certiSearch.values.cardType
                        : "Select Card Type"
                    }
                    options={adminCards}
                    isSearchable={true}
                    isClearable={true}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        certiSearch.setFieldValue(
                          "cardType",
                          selectedOption.value
                        );
                      } else {
                        certiSearch.setFieldValue("cardType", "");
                      }
                    }}
                    onBlur={() => certiSearch.setFieldTouched("cardType", true)}
                  />
                ) : null}

                {certiSearch.errors.cardType && certiSearch.touched.cardType ? (
                  <span className="text-danger">
                    {certiSearch.errors.cardType}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Certificate Status</label>
                <select
                  id="ceckstatus"
                  className="form-select dark-border"
                  onChange={(e) => {
                    certiSearch.handleChange(e);
                    changeRecieveMethod(e.target.value);
                  }}
                  name="certiStatus"
                  value={certiSearch.values.certiStatus || ""}
                  onBlur={certiSearch.handleBlur}
                >
                  <option value="" style={{ backgroundColor: "gray" }}>
                    STATUS
                  </option>
                  <option value="Active" style={{ backgroundColor: "green" }}>
                    Active
                  </option>
                  <option
                    value="Voucher Mailed"
                    style={{ backgroundColor: "purple" }}
                  >
                    Voucher Mailed
                  </option>
                  <option
                    value="Voucher Received"
                    style={{ backgroundColor: "SandyBrown" }}
                  >
                    Voucher Received
                  </option>
                  <option
                    value="Card Ordered"
                    style={{ backgroundColor: "pink" }}
                  >
                    Card Ordered
                  </option>
                  <option
                    value="Card Mailed"
                    style={{ backgroundColor: "SkyBlue" }}
                  >
                    Card Mailed
                  </option>
                  <option value="Expired" style={{ backgroundColor: "Salmon" }}>
                    Expired
                  </option>
                  <option value="Void" style={{ backgroundColor: "red" }}>
                    Void
                  </option>
                </select>

                {certiSearch.errors.certiStatus &&
                certiSearch.touched.certiStatus ? (
                  <span className="text-danger">
                    {certiSearch.errors.certiStatus}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Status Date</label>
                <input
                  type="date"
                  className="form-control "
                  aria-label="Disabled input example"
                  disabled
                  placeholder="Status Date"
                  name="statusDate"
                  value={certiSearch.values.statusDate || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.statusDate &&
                certiSearch.touched.statusDate ? (
                  <span className="text-danger">
                    {certiSearch.errors.statusDate}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Receive Method</label>
                <select
                  className="form-select dark-border"
                  name="recMeth"
                  value={certiSearch.values.recMeth || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                >
                  <option value="">Select Receive Method</option>
                  <option value="Mail">Mail</option>
                  <option value="Phone">Phone</option>
                  <option value="Web">Web</option>
                </select>
                {certiSearch.errors.recMeth && certiSearch.touched.recMeth ? (
                  <span className="text-danger">
                    {certiSearch.errors.recMeth}
                  </span>
                ) : null}
              </div>

              <div className="col-12 add-space-btns">
                <button type="submit" className="btn btn-primary mt-2">
                  {isEditing
                    ? "Update Certificate"
                    : "Search Certificate Record"}
                </button>
                <Link to="/search_mpl_jobs" className="btn btn-primary mt-2">
                  Multiple Job Search
                </Link>
                <button
                  type="button"
                  className="btn btn-primary mt-2"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Reset Form
                </button>
                <Link to="/searchcomp_pav" className="btn btn-primary mt-2">
                  Mail Merge
                </Link>
                <Link
                  className="btn btn-primary mt-2"
                  to="/searchmail_pav_hard"
                >
                  Card to be Mailed
                </Link>
                <Link
                  to="/searchmail_pav_virtual"
                  className="btn btn-primary mt-2"
                >
                  Virtual Card
                </Link>
                <a
                  href={
                    process.env.REACT_APP_API_Link + "exportLastCertificates"
                  }
                  className="btn btn-primary mt-2"
                >
                  Last Upload Export as Excel
                </a>
              </div>
            </form>
          </div>
        </div>
        {showCertiRecord ? (
          <div className="card search-certificate-result mt-3">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Certificate</h5>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-md-8 col-lg-6">
                  <div className="input-group mt-2 mb-2">
                    <label className="search-certificate-label">
                      Change Certificate Status
                    </label>

                    <select
                      className="form-select  dark-border"
                      ref={statusKey}
                    >
                      <option value="" style={{ backgroundColor: "gray" }}>
                        STATUS
                      </option>
                      <option
                        value="Active"
                        style={{ backgroundColor: "green" }}
                      >
                        Active
                      </option>
                      <option
                        value="Voucher Mailed"
                        style={{ backgroundColor: "purple" }}
                      >
                        Voucher Mailed
                      </option>
                      <option
                        value="Voucher Received"
                        style={{ backgroundColor: "SandyBrown" }}
                      >
                        Voucher Received
                      </option>
                      <option
                        value="Card Ordered"
                        style={{ backgroundColor: "pink" }}
                      >
                        Card Ordered
                      </option>
                      <option
                        value="Card Mailed"
                        style={{ backgroundColor: "SkyBlue" }}
                      >
                        Card Mailed
                      </option>
                      <option
                        value="Expired"
                        style={{ backgroundColor: "Salmon" }}
                      >
                        Expired
                      </option>
                      <option value="Void" style={{ backgroundColor: "red" }}>
                        Void
                      </option>
                    </select>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={changeAllRecords}
                    >
                      Change All Record
                    </button>
                  </div>
                </div>
                <div className="col-md-4 col-lg-6 text-end">
                  {exportLink !== "" ? (
                    <Link to={exportLink} className="btn btn-primary mt-2 mb-2">
                      Export As Excel
                    </Link>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div className="col-md-8 col-lg-4 ms-auto">
                  <div className="input-group mt-2 mb-2">
                    <select className="form-select" ref={selectKey}>
                      <option value="all">All</option>
                      <option value="FIRST_NAME">First Name</option>
                      <option value="LAST_NAME">Last Name</option>
                      <option value="PHONE_NUMBER">Phone Number</option>
                      <option value="EmailAddress">Email</option>
                      <option value="ADDRESS">Address</option>
                      <option value="CITY">City</option>
                      <option value="STATE">State</option>
                      <option value="ZIP">Zip</option>
                      <option value="DENOMINATION">Denomination</option>
                      <option value="CUSTOMER">Company</option>
                      <option value="CERT_NUMBER">Cert Number</option>
                      <option value="REDEMPTION_BEINS">Begin Redemption</option>
                      <option value="REDEMPTION_ENDS">End Redemption</option>
                      <option value="JOB_NUMBER">Job Number</option>
                      <option value="CERT_STATUS">Cert Status</option>
                    </select>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => {
                        customSearchRecord(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <CertificateSearchRecords
                data={JobDetailsData}
                allData={CertifecateRecordData}
              />
            </div>
          </div>
        ) : null}
      </main>
      <div
        className="modal fade"
        id="exampleVerticallycenteredModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirmation Delet Record</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h5 className="m-0">Are you sure you want to delete Record.</h5>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" className="btn btn-danger">
                Yes Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToTop />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
