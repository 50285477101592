import React from "react";
import cityLogo from "../Images/logo.webp";
import LanguageTranslate from "./LanguageTranslate";
function HeaderUser() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="translator-holder">
            <div className="logo">
              <img
                src={cityLogo}
                alt="city-logo.png"
                className="img-responsive"
                width="200"
                height="105"
              />
            </div>
            <LanguageTranslate />
          </div>
        </div>
        <div className="fix-white"></div>
      </div>
    </div>
  );
}
export default HeaderUser;
