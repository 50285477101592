import React, { useEffect, useState } from "react";
import AdminListService from "../../../services/admin-list.service";
import useTable from "../../../hooks/useTable";
import useSortableData from "../../../hooks/useSortableData";
import Pagination from "react-js-pagination";
import Skeleton from "react-loading-skeleton";
import job from "./images/icon-9.png";
export default function AllCustomers({
  setCustomerSiteId,
  setCustomer_id,
  setAdminId,
}) {
  const [loading, setLoading] = useState(false);
  const [tableData, settableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [limit, setLimit] = useState("20");

  const plastic_manage = async (page) => {
    setLoading(true);
    try {
      const responce = await AdminListService.listPlasticManage(page);

      if (responce.status === 200) {
        const res = responce.data.data.data;
        const results = [];
        res.map((value) => {
          return results.push({
            SiteId: value.SiteId,
            SiteName: value.SiteName,
            AdminId: value.AdminId,
            SiteUrl: value.SiteUrl,
            UserName: value.UserName,
            Password: value.Password,
            voucherCount: value.voucherCount,
          });
        });

        settableData([...results]);
        setFilteredData([...results]);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);
      setFilteredData([]);
    }
  };

  useEffect(() => {
    plastic_manage("1");
  }, []);

  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    // Filter the data based on the search query
    if (searchQuery !== "") {
      const newFilteredData = tableData.filter((item) => {
        // Customize this condition based on your data structure and search requirements
        return (
          item.SiteName.toString().toLowerCase().includes(searchQuery) ||
          item.Password.toString().toLowerCase().includes(searchQuery) ||
          item.UserName.toString().toLowerCase().includes(searchQuery) ||
          item.SiteUrl.toString().toLowerCase().includes(searchQuery)
        );
      });

      setFilteredData(newFilteredData);
    } else {
      setFilteredData(tableData);
    }
  };

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, rowsPerPage);
    const { items } = useSortableData(slice);

    return (
      <div className="table-responsive mt-3">
        <div className="table-responsive">
          <table className="table table-striped align-middle w-100">
            <thead className="table-dark">
              <tr>
                <th>Sr#</th>
                <th>Site Name </th>
                <th>Site Address </th>
                <th>Site User Name </th>
                <th>No. of Vouchers</th>

                {/* <th>Steps Taken</th> */}
                <th width="250px">Action</th>
              </tr>
            </thead>
            <tbody>
              {items.length ? (
                items.map((el, index) => (
                  <tr key={index}>
                    <td>
                      {page === 1
                        ? index + 1
                        : (page - 1) * rowsPerPage + index + 1}
                    </td>
                    <td>{el.SiteName}</td>
                    <td>{el.SiteUrl}</td>
                    <td>{el.UserName}</td>
                    <td>{el.voucherCount}</td>
                    {/* <td>{el.Password}</td> */}

                    <td className="action-job">
                      <button
                        className="btn btn-outline-info add-job ms-0"
                        type="button"
                        onClick={() => {
                          setCustomerSiteId(el.SiteId);
                          setCustomer_id(el.AdminId);
                          setAdminId(el.AdminId);
                        }}
                      >
                        <img src={job} alt="missing" /> Process
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center text-capitalize">
                    No record found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {data.length && data.length > rowsPerPage ? (
          <Pagination
            activePage={page}
            itemsCountPerPage={parseInt(rowsPerPage)}
            totalItemsCount={data.length}
            onChange={(e) => {
              setPage(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </div>
    );
  };
  return (
    <div className="card">
      <div className="card-header">All Customer</div>
      <div className="card-body">
        {loading ? (
          <Skeleton count={5} height="48px" />
        ) : (
          <>
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">Search</label>
                  <input
                    type="text"
                    onChange={handleSearch}
                    placeholder="Type to search"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">Show Records</label>
                  <select
                    className="form-select "
                    onChange={(e) => {
                      setLimit(e.target.value);
                    }}
                  >
                    <option value="20">20</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="1500">1500</option>
                    <option value={tableData.length}>All</option>
                  </select>
                </div>
              </div>
            </div>
            <Table data={filteredData} rowsPerPage={limit} />
          </>
        )}
      </div>
    </div>
  );
}
